
.account-content {
    min-width: 1400px;

    .popover-box {
        font-size: 12px;
        color: #444444;
        line-height: 20px;
    }

    .purple-btn {
        width: 90px;
        height: 30px;
        background: #564CED;
        border-radius: 6px;
        color: #ffffff;
        line-height: 0;
    }

    .head-box {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        position: relative;

        .left-box {
            background: #ffffff;
            border-radius: 4px;

            .select-img {
                width: 27px;
                height: 27px;
                border-radius: 50%;
                position: absolute;
                left: 10px;
                top: 6px;
                z-index: 1;
            }

            ::v-deep .el-input__inner {
                padding-left: 40px;
            }

            ::v-deep .el-input__suffix .el-input__suffix-inner .el-icon-arrow-up {
                &:before {
                    content: '\e78f';
                    color: #333333;
                }
            }

            ::v-deep .el-select-dropdown .el-select-dropdown__item {
                display: flex;
                align-items: center;

                span {
                    margin-left: 10px;
                }

                img {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                }
            }
        }
    }

    .account-info-box {
        background: #ffffff;
        margin-top: 15px;
        border-radius: 6px;

        .head-time {
            text-align: right;
            padding: 18px;
            color: #666666;
            font-size: 12px;

            .update-title {
                cursor: pointer;
                margin-left: 9px;

                i {
                    font-size: 14px;
                }
            }
        }

        .main-content {
            display: flex;

            .bottom-box {
                display: flex;
                justify-content: center;
                cursor: pointer;

                .icon-box {
                    width: 36px;
                    height: 37px;
                    border-radius: 50%;
                    background: #5E54F1;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i {
                        font-size: 24px;
                    }
                }

                .bottom-text {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 10px;
                }
            }
        }

        .left-box {
            margin: 40px 0 30px 45px;
            padding-right: 100px;
            border-right: 5px solid #EEEEEE;

            .top-box {
                display: flex;

                .head-pic img {
                    width: 110px;
                    height: 110px;
                    border-radius: 50%;
                }

                .head-info {
                    width: 150px;
                    margin-left: 25px;

                    .account-name {
                        margin-top: 15px;
                        font-size: 20px;
                        color: #000000;
                        max-width: 150px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .account-phone {
                        margin-top: 45px;
                        font-size: 14px;
                        color: #888888;
                        max-width: 150px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }

            .bottom-box {
                margin-top: 60px;
                text-align: center;
            }

            .head-pic img {
                width: 110px;
                height: 110px;
            }
        }

        .middle-box {
            min-width: 700px;
            margin: 55px 0 30px 100px;
            border-right: 5px solid #EEEEEE;
            display: flex;
            flex-wrap: wrap;

            .info-box {
                width: calc(33% - 80px);
                text-align: center;
                padding-left: 60px;

                .top-box {
                    display: flex;
                    padding-left: 25px;

                    i {
                        font-size: 24px;
                    }

                    .top-title {
                        font-size: 14px;
                        height: 100%;
                        color: #888888;
                        margin-left: 8px;
                    }

                    .top-question {
                        margin-left: 8px;
                        margin-top: 2px;

                        .el-icon-question {
                            font-size: 16px;
                            color: #645AF1;
                            cursor: pointer;
                        }
                    }
                }

                .bottom-box {
                    text-align: center;
                    margin-top: 15px;
                    font-weight: bold;
                    color: #333333;
                    font-size: 24px;
                    max-width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                &:nth-of-type(3n+1) {
                    padding-right: 30px;
                    padding-left: 0;
                }

                &:nth-of-type(3n+2) {
                    position: relative;
                    padding-right: 40px;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 1px;
                        height: 36px;
                        background: #EEEEEE;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 1px;
                        height: 36px;
                        background: #EEEEEE;
                    }
                }

            }
        }

        .right-box {
            margin: 55px 0 30px 30px;
            padding-right: 50px;

            .right-main-content {
                display: flex;
            }

            .right-bottom {
                text-align: center;
                display: flex;
                justify-content: center;

                .purple-btn {
                    display: flex;
                    justify-content: center;
                    line-height: 4px;
                }
            }

            .right-box-item {
                position: relative;
                padding: 50px 35px;

                .item-top {
                    width: 84px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #888888;
                }

                .item-bottom {
                    margin-top: 30px;
                    font-weight: bold;
                    color: #333333;
                    font-size: 24px;
                    text-align: center;
                    max-width: 84px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                &:first-child {
                    &:after {
                        content: '';
                        position: absolute;
                        top: 60px;
                        right: 0;
                        width: 1px;
                        height: 36px;
                        background: #EEEEEE;
                    }
                }

            }
        }
    }

    .left-item-fan {
        margin-bottom: 20px;
        margin-top: 20px;

        .item-half-sub {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: #000000;
        }

        .left-item-content {
            background: #fff;
            border-radius: 6px;
            margin-top: 15px;
            padding: 15px;

            .fan-tab {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                text-align: center;
                padding-right: 30px;
                color: #999;
                line-height: 1;
                color: #666666;

                .item {
                    cursor: pointer;
                    width: 50px;
                    height: 30px;
                    background: #F1F0FE;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &.left {
                        border-radius: 5px 0px 0px 5px;
                    }

                    &.right {
                        border-radius: 0px 5px 5px 0px;
                    }
                }

                .current {
                    color: #FFFFFF;
                    background: #564CED;
                }
            }

            ::v-deep.tooltip {
                height: 59px;
                border-radius: 2px;
                box-shadow: 0px 2px 9px 1px rgba(0, 0, 0, 0.11);
                background: #fff;

                &.double {
                    height: 91px;
                }

                .tooltip-box {
                    padding: 13px 20px 10px;
                }

                .tooltip-title {
                    font-size: 12px;
                    color: #999999;
                }

                .tooltip-title-right {
                    float: right;
                    padding-right: 10px;
                    font-size: 16px;

                    &.red {
                        color: #F5697B;
                    }

                    &.blue {
                        color: #5F55F1;
                    }

                    &.purple {
                        color: #5F55F1;
                    }
                }

                .tooltip-title-left {
                    color: #333;
                }
            }
        }

        .item-content {
            display: flex;
            justify-content: space-between;

            .title {
                font-size: 16px;
                color: #333333;
            }

            .left_box {
                border-radius: 6px;
                display: flex;
                background-color: #ffffff;
                flex-direction: column;
                width: calc(50% - 20px);
                height: 100%;
                border-right: 1px solid #eaeaea;
                margin: 10px 20px 0 0;

                .gender-box {
                    display: flex;
                    flex-direction: column;
                    padding: 40px 30px;

                    .gender-chart {
                        margin-top: 100px;
                        padding: 0 30px;

                        .icon-box {
                            display: flex;
                            justify-content: space-between;
                            margin-top: 74px;

                            .icon-title {
                                margin-top: 22px;
                                font-size: 14px;
                                color: #444444;
                            }

                            .women-box {
                                text-align: center;

                                i {
                                    color: #FF6A6A;
                                }
                            }

                            .man-box {
                                text-align: center;

                                i {
                                    color: #564CED;
                                }
                            }

                            i {
                                font-size: 35px;
                            }
                        }

                        .chart-content {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            height: 13px;

                            .man {
                                border-radius: 6px;
                                background-color: #564CED;
                            }

                            .women {
                                flex: 1;
                                border-radius: 6px;
                                background-color: #FF6A6A;
                            }
                        }

                        .bottom-box {
                            font-size: 18px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #000000;
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 30px;

                            .box1 {
                                display: flex;
                                flex-direction: column;
                            }
                        }
                    }
                }
            }

            .right_box {
                display: flex;
                border-radius: 6px;
                background-color: #ffffff;
                flex-direction: column;
                width: 50%;
                height: 100%;
                margin: 10px 0 0;

                .age-box {
                    padding: 40px 30px;
                }

                .area-distribution {
                    flex: 1;
                    margin-top: 47px;
                    display: flex;
                    flex-direction: column;
                    height: calc(100% - 66px);
                }
            }
        }

        &.end {
            margin-bottom: 0;
        }

        .college-table {
            width: 100%;
            margin-top: 22px;

            ::v-deep .el-table__header .platform-head {
                padding-left: 25px;
            }

            .account-number {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 45px;

                &.platform {
                    padding-left: 20px;
                }

                img {
                    height: 36px;
                    width: 36px;
                    border-radius: 50%;
                }

                .scope-title {
                    width: 260px;
                    margin-left: 10px;
                    text-align: left;
                    color: #333;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: inline-block;
                    cursor: pointer;
                    font-size: 14px;
                    font-weight: 400;
                }
            }

            .icon-box {
                display: flex;
                justify-content: center;

                .icon-content {
                    width: 40px;
                    height: 40px;
                    background: #FF6A6A;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    i {
                        font-size: 20px;
                        color: #ffffff;
                    }
                }
            }
        }

        .circle-page {
            padding-top: 35px;
        }

    }
}
